<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <template slot="title">
      <h3 class="title m-0">
        Teachers Form
      </h3>
    </template>
    <template slot="body">
      <form-text
        v-model="model.name"
        name="name"
        rules="required"
        icon="face"
        disabled
      />

      <div class="d-flex">
        <ClassroomsAutocomplete
          ref="class_ac"
          label="Add Classroom"
          class="text-left w-100"
          @change="selClassroom = $event"
        />
        <md-button
          class="md-icon-button my-2 md-success"
          @click="addClassroom"
        >
          <md-icon>add</md-icon>
        </md-button>
      </div>

      <ul class="list-unstyled">
        <li
          v-for="(classroom, i) of model.classrooms"
          :key="classroom.classroom_id"
        >
          <md-button
            class="md-danger md-icon-button md-simple my-1"
            @click="deleteClassroom(i)"
          >
            <md-icon>delete</md-icon>
          </md-button>
          {{ classroom.classroom_code }} - {{ classroom.classroom_name.name }}
        </li>
      </ul>
    </template>
    <template slot="footer">
      <md-button
        class="btn-previous"
        @click.native="close"
      >
        Cancel
      </md-button>
      <md-button
        class="md-success"
        @click="saveTeacher"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { FormText } from '@/components/Inputs';
import { ClassroomsAutocomplete } from '@/components/Inputs/selects';

extend('required', required);

export default {
  components: {
    FormText,
    ClassroomsAutocomplete,
  },
  props: {
    teacher: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      model: {
        name: null,
        classrooms: [],
      },
      selClassroom: null,
    };
  },
  mounted() {
    if (this.teacher) {
      this.model = JSON.parse(JSON.stringify(this.teacher));
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    addClassroom() {
      if (this.selClassroom) {
        let found = false;
        if (this.model.classrooms.length) {
          found = this.model.classrooms.some((x) => {
            const bool = x.classroom_id === this.selClassroom.classroom_id;
            return bool;
          });

          if (found) {
            this.fireError('This teacher is already assigned to the selected classroom');
          }
        }

        if (!found) {
          this.model.classrooms.push(this.selClassroom);
        }

        this.$refs.class_ac.clear();
      } else {
        this.fireError('Please search and select a Classroom to Add');
      }
    },
    deleteClassroom(i) {
      this.model.classrooms.splice(i, 1);
    },
    saveTeacher() {
      this.request(this.$API.TEACHERS + this.teacher.user_id, 'put', this.model, () => {
        this.fireSuccess('Teacher Updated succesfully');
        this.$emit('onSaved');
        this.close();
      });
    },
  },
};
</script>

<style lang="scss">
  .list-unstyled {
    list-style: none;
    padding-left: 10px;
  }
</style>
